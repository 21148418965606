<template>
  <div>
    <page-header
      :background="require('@/assets/leagues/img.png')"
      title="Notícias"
    />

    <v-container class="py-12">
      <div class="d-flex flex-column">
        <!-- loading -->
        <loader-content v-if="loading" class="my-12" />

        <!-- content -->
        <section v-else-if="news.length > 0">
          <v-row
            v-for="(item, i) in news"
            :key="i"
            justify="center"
            justify-md="start"
            class="mb-6"
          >
            <v-col cols="10" sm="8" md="4" lg="3" xl="2">
              <v-sheet v-if="i % 2 !== 0" height="8" color="primary" />

              <v-card color="surface" tile flat>
                <v-img :src="item.thumb" :aspect-ratio="16 / 9" />
              </v-card>

              <v-sheet v-if="i % 2 === 0" height="8" color="primary" />
            </v-col>

            <v-col cols="10" sm="8" lg="9" xl="10">
              <div class="d-flex flex-column py-4">
                <div class="text-body-2 font-weight-bold mb-1">
                  <span class="saira">{{ formatDate(item.createdAt) }}</span>
                </div>

                <div class="text-h5 font-weight-bold primary--text mb-4">
                  <span class="saira">
                    {{ String(item.title).toUpperCase() }}
                  </span>
                </div>

                <div class="text-body-1">
                  {{ item.description }}
                </div>
              </div>
            </v-col>
          </v-row>

          <div class="d-flex justify-center align-center">
            <v-btn
              v-if="pagination.page < pageCount - 1"
              class="rounded-l-0 rounded-r-lg text-none"
              color="primary"
              outlined
              large
              @click="showMore()"
            >
              <v-icon left>mdi-arrow-down</v-icon> Ver mais
            </v-btn>

            <v-btn
              v-else
              class="rounded-l-0 rounded-r-lg text-none"
              color="primary"
              outlined
              large
              @click="recall()"
            >
              <v-icon left>mdi-arrow-up</v-icon> Recolher
            </v-btn>
          </div>
        </section>

        <!-- empty -->
        <div v-else class="d-flex flex-column align-center pb-12">
          <span class="text-body-1 text-center subtext--text">
            Nenhum resultado foi encontrado
          </span>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { getNews } from "@/services/news.js";
import { formatDate } from "@/utils";

export default {
  data() {
    return {
      loading: true,
      pagination: {
        page: 0,
        size: 10,
        total: 1,
      },
      news: [],
    };
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    pageCount() {
      let value = this.pagination.total / this.pagination.size;
      if (value % 1 !== 0) value = value + 1;

      return Math.trunc(value);
    },
  },

  methods: {
    async getData() {
      try {
        const payload = {
          page: this.pagination.page,
          size: this.pagination.size,
        };

        await getNews(payload).then((res) => {
          this.news = this.news.concat(res.news);
          this.pagination.total = res.total;
        });
      } catch (err) {
        // err
      } finally {
        this.loading = false;
      }
    },

    showMore() {
      this.loading = true;
      this.pagination.page += 1;
      this.getData();
    },

    recall() {
      this.loading = true;
      this.pagination.page = 0;
      this.news = [];
      this.getData();
    },

    formatDate,
  },
};
</script>

<style></style>
